import { Injectable } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Injectable({
    providedIn: "root"
})
export class DialogsService {

    // tslint:disable-next-line:no-empty
    public constructor() {}

    public changeNavbarClass(): void {
        document.getElementById("toolBar").id = "myToolbarStyle";
    }

    // tslint:disable-next-line:no-any
    public afterDialogClose(dialogRef: MatDialogRef<any>): void {
        dialogRef.afterClosed().subscribe((status: string) => {
            document.getElementById("myToolbarStyle").id = "toolBar";
        });
    }
}
