import { Injectable } from "@angular/core";
import { CanActivate, Router} from "@angular/router";
import { LoginService } from "../services/login.service";
@Injectable({
    providedIn: "root"
})

// tslint:disable-next-line:filename-typename
export class AuthGuard implements CanActivate {
    public constructor(private router: Router, private loginService: LoginService) {}

    public canActivate(): boolean {
        if (!this.loginService.isAuthenticated()) {
            this.router.navigate(["/"]);
            return false;
        }
        return true;
    }

}
