import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import "rxjs/add/observable/throw";
import "rxjs/add/operator/catch";
import { Observable } from "rxjs/internal/Observable";
import { environment } from "../../environments/environment";
@Injectable({
    providedIn: "root"
})
export class LoginService {
    private jwtHelper: JwtHelperService = new JwtHelperService();

    public constructor(private http: HttpClient, private router: Router) { }

    public logOutUser(): void {
        localStorage.removeItem("token");
        this.router.navigate(["/"]);
    }

    public getToken(): string {
        return localStorage.getItem("token");
    }

    public isAuthenticated(): boolean {
        const token: string = this.getToken();
        return !this.jwtHelper.isTokenExpired(token);
    }

    // tslint:disable-next-line:no-any
    public login(user: any): Observable<object> {
        return this.http.post(environment.backEnd_Url + "/user_token", {
            auth: {
                email: user.email,
                password: user.password
            },
        });
    }
}
