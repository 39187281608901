import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { LoginService } from "./services/login.service";
@Injectable()
// tslint:disable-next-line:filename-typename
export class TokenInterceptor implements HttpInterceptor {
    public constructor(public auth: LoginService) { }
    // tslint:disable-next-line:no-any
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.headers.get("X-Upload") !== null) {
            return next.handle(request);
        }
        // tslint:disable-next-line:no-parameter-reassignment
        request = request.clone({
            setHeaders: {
                Authorization: `Bearer ${this.auth.getToken()}`
            }
        });
        return next.handle(request);
    }
}
