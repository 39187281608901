import { Component } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
    MatDialogRef
} from "@angular/material/dialog";
import { DialogsService } from "src/app/services/dialogs.service";

@Component({
    selector: "delete-item-dialog",
    templateUrl: "./delete-item-dialog.component.html",
    styleUrls: ["./delete-item-dialog.component.css"]
})

export class DeleteItemDialogComponent {
    public newUserForm: FormGroup;
    public constructor(
        public dialogRef: MatDialogRef<DeleteItemDialogComponent>,
        public dialogsService: DialogsService,
    ) {
        this.dialogsService.changeNavbarClass();
        this.dialogsService.afterDialogClose(this.dialogRef);
    }

    public deleteItem(): void {
        this.dialogRef.close("Yes");
    }
    public closeDialog(): void {
        this.dialogRef.close("No");
    }

}
