import { PlatformLocation } from "@angular/common";
import { Component } from "@angular/core";
import { environment } from "../environments/environment";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"]
})
export class AppComponent {

    public constructor(platformLocation: PlatformLocation) {
        // tslint:disable-next-line:no-any
        const hostname: string = (platformLocation as any).location.hostname;
        if (hostname === "manage.culturemaps.net") {
            environment.backEnd_Url = "https://api.culturemaps.net/manage";
        } else if (hostname === "dev.manage.culturemaps.net") {
            environment.backEnd_Url = "https://dev.api.culturemaps.net/manage";
        } else if (hostname === "stage.manage.culturemaps.net") {
            environment.backEnd_Url = "https://stage.api.culturemaps.net/manage";
        } else {
            environment.backEnd_Url = "https://dev.api.culturemaps.net/manage";
        }
    }
}
