import { CommonModule } from "@angular/common";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SnotifyService, ToastDefaults } from "ng-snotify";
import { NgxSpinnerModule } from "ngx-spinner";
import { MaterialModule } from "../app/modules/material.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { DeleteItemDialogComponent } from "./dialogs/delete-item-dialog/delete-item-dialog.component";
import { LoginComponent } from "./login/login.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { LoginService } from "./services/login.service";
import { TokenInterceptor } from "./token.interceptor";

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        NavbarComponent,
        DeleteItemDialogComponent,
    ],

    imports: [
        CommonModule,
        BrowserModule,
        MaterialModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        NgxSpinnerModule,
        FlexLayoutModule,

    ],
    entryComponents: [
        DeleteItemDialogComponent
    ],
    providers: [
        LoginService,
        BrowserAnimationsModule,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        { provide: "SnotifyToastConfig", useValue: ToastDefaults },
        SnotifyService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
