import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth-guard/auth.guard";
import { NoAuthGuard } from "./auth-guard/no-auth.guard";
import { LoginComponent } from "./login/login.component";

// tslint:disable-next-line:naming-convention
const appRoutes: Routes = [
    { path: "", redirectTo: "login", pathMatch: "full" },
    { path: "pois", loadChildren: "./pois/pois.module#PoisModule", canActivate: [AuthGuard]},
    { path: "tours", loadChildren: "./tours/tours.module#ToursModule", canActivate: [AuthGuard]},
    // tslint:disable-next-line:max-line-length
    { path: "suggestions", loadChildren: "./suggestions/suggestions.module#SuggestionsModule", canActivate: [AuthGuard]},
    { path: "categories", loadChildren: "./categories/categories.module#CategoriesModule", canActivate: [AuthGuard] },
    { path: "news", loadChildren: "./news/news.module#NewsModule", canActivate: [AuthGuard] },
    { path: "cities", loadChildren: "./cities/cities.module#CitiesModule", canActivate: [AuthGuard] },
    { path: "users", loadChildren: "./users/users.module#UsersModule", canActivate: [AuthGuard] },
    { path: "pois/:id/reviews",  loadChildren: "./reviews/reviews.module#ReviewsModule", canActivate: [AuthGuard] },
    { path: "pois/:id/images", loadChildren: "./images/images.module#ImagesModule", canActivate: [AuthGuard] },
    { path: "login", component: LoginComponent, canActivate: [NoAuthGuard] },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(appRoutes, { enableTracing: false })],
})
export class AppRoutingModule { }
