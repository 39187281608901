import { HttpErrorResponse } from "@angular/common/http";
import { Component } from "@angular/core";
import {
    FormBuilder,
    FormGroup,
    Validators
} from "@angular/forms";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { LoginService } from "../services/login.service";
@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"]
})
export class LoginComponent {

    public form: FormGroup;
    public mode: string = "determinate";
    public email: string;
    public error: string = "";
    public isSubmit: boolean = false;
    public constructor(
        public formBuilder: FormBuilder,
        public loginService: LoginService,
        public router: Router,
        private spinner: NgxSpinnerService
    ) {
        if (this.loginService.isAuthenticated()) {
            this.router.navigate(["pois"]);
            return;
        }

        this.form = this.formBuilder.group({
            email: ["",
                    [
                        Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"),
                        Validators.email,
                        Validators.minLength(11),
                        Validators.maxLength(50)
                    ]
                ],
            password: ["",
                       [
                        Validators.required,
                        Validators.minLength(8),
                        Validators.maxLength(150),
                    ]

                ],
        });
        this.onLoginFormValueChanges();
    }

    public isFormValid(): void {
        this.spinner.show();
        if (this.form.valid) {
            this.isSubmit = false;
            this.login();
        } else {
            this.isSubmit = true;
            this.spinner.hide();
        }
    }

    public login(): void {
        this.loginService.login(this.form.value).subscribe(
            // tslint:disable-next-line:no-any
            (res: any) => {
                localStorage.setItem("token", res.jwt);
                this.spinner.hide();
                this.router.navigate(["pois/"]);
            },
            (error: HttpErrorResponse) => {
                this.spinner.hide();
                this.error = "Die E-Mail-Adresse oder das Passwort ist nicht korrekt.";
            });
    }

    public onLoginFormValueChanges(): void {
        this.form.valueChanges.subscribe((value: string) => {
            this.error = "";
        });
    }
}
