import { Component } from "@angular/core";
import { LoginService } from "../services/login.service";
@Component({
    selector: "app-navbar",
    templateUrl: "./navbar.component.html",
    styleUrls: ["./navbar.component.css"]
})
export class NavbarComponent  {

    public constructor(public loginService: LoginService) { }
}
